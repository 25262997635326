import React from "react"
import AuthorImage from "./components/AuthorImage"

export default function GliAutori() {
  return (
    <section className="section-gliautori">
      <div className="container">
        <div className="columns is-centered px-5">
          <div className="column is-four-fifths">
            <h1>GLI<br />AUTORI</h1>
            <div className="columns is-centered">
              <div className="column is-two-fifths">
                <picture><AuthorImage fileName="daniela.png" alt="Daniela Bavuso" /></picture>
                <h3>Daniela Bavuso</h3>
                <p className="mt-3">&Egrave; una strategist e value designer con oltre 15 anni di esperienza come consulente professionale in
                  strategie di comunicazione e gestione delle relazioni con gli stakeholder.
                  Dopo varie esperienze come strategist tra pubblico e privato nel 2018 ha fondato Makaeaplan.io,
                  uno studio di consulenza strategica.
                  Impegnata in progetti che incidono sulla vita delle persone e crede che le sfide globali e i
                  nuovi valori sociali stiano cambiando profondamente il modo in cui le organizzazioni lavorano e
                  producono il loro reddito. Per questo motivo aiuta le organizzazioni a scoprire la loro architettura del valore,
                  a riprogettare la catena del valore e il modello di business e a pianificare un'efficace
                  strategia di business e comunicazione basata sullo scopo di far crescere il business,
                  trovando un modo per creare un impatto rilevante. E’ coinvolta in molti programmi educativi e di business coaching.</p>
                  <p className="mt-3"><a href="https://makeaplan.io">makeaplan.io</a></p>
              </div>
              <div className="column is-two-fifths is-offset-1">
                <picture><AuthorImage fileName="natale.png" alt="Natale Cardone" /></picture>
                <h3>Natale Cardone</h3>
                <p className="mt-3">Natale si occupa di brand design da oltre 25 anni. 
                  Per lui il brand design è un processo creativo che traduce la strategia di branding di un'azienda in una identità 
                  unica e riconoscibile che si esprima in modo efficace attraverso la comunicazione visiva, dal corporate all’exhibit design; 
                  dal packaging ai progetti editoriali e digitali. Dopo gli studi, durante un viaggio a New York, incontra Massimo Vignelli, 
                  uno dei designer italiani più famosi nel mondo e comincia con lui una stimolante collaborazione e amicizia. I due condividono 
                  la passione per il design ma anche la disciplina, l'attenzione al dettaglio, la responsabilità e il rispetto della professione.
                  Negli anni successivi collabora con Fabrica, il Centro Ricerche e Comunicazione del Gruppo Benetton dove si confronta 
                  con giovani creativi provenienti da tutto il mondo. Espone i suoi progetti presso prestigiosi spazi espositivi come lo 
                  Shiodome Italia Creative Centre di Tokyo e alla Triennale di Milano. Partecipa inoltre come giudice a concorsi 
                  internazionali ed è docente in programmi educativi sul design. Oggi Natale continua a lavorare per rinomate aziende 
                  nazionali e internazionali appartenenti a vari settori merceologici, ma non solo, aiuta gli imprenditori che hanno la necessità 
                  di progettare identità visive per nuovi prodotti o servizi.</p>
                  <p className="mt-3"><a href="https://natalecardone.com">natalecardone.com</a></p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}
